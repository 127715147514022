import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StorageKeys } from '../../../api/constants';
import { _getEnv } from '../../../api/utils';
import ContinueButton from '../../../components/continueButton/ContinueButton';
import { getPortalUrl } from '../../../utils/utils';
import { STEPS } from '../../constants';

const TRANSLATION_ROOT = `setup.${STEPS.SETUP_COMPLETE}.content`;
const TRANSLATION_BUTTON = 'common.continue-imprivata-access';

const SetupComplete = () => {
  const { t } = useTranslation();
  const { currentEnv, tld } = _getEnv();

  useEffect(() => {
    console.log('[Setup Complete] Setup State: ', sessionStorage.getItem('tenantSetupState'));
    if (sessionStorage.getItem(StorageKeys.TENANT_SETUP_STATE)) {
      sessionStorage.setItem(StorageKeys.TENANT_SETUP_STATE, 'completed');
    }
  }, []);

  const handleGoToImprivataAccess = () => {
    localStorage.clear();
    sessionStorage.clear();
    const portalUrl = getPortalUrl();
    window.location.href = `${portalUrl}`;
  };

  return (
    <>
      <p style={{ marginBottom: '8.13rem' }}>
        <Trans i18nKey={`${TRANSLATION_ROOT}.description`} />
      </p>
      <ContinueButton label={t(TRANSLATION_BUTTON)} onClick={handleGoToImprivataAccess} />
    </>
  );
};

export default SetupComplete;

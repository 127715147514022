import { Form } from 'antd';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { v4 as uuidV4 } from 'uuid';
import { StorageKeys } from '../../../api/constants';
import ContinueButton from '../../../components/continueButton/ContinueButton';
import { getFeatureFlags } from '../../../featureFlagsUtils';
import { initTracerSetup } from '../../../utils/tracer';
import useStartTenantSetup from '../../hooks/useStartTenantSetup';

const TRANSLATION_ROOT = 'setup.content';

const SetupLandingPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [dpaNoActionNeeded, setDpaNoActionNeeded] = useState(false);
  const [isLoading, setLoading] = useState(false);

  // Generate and store workflowId in sessionStorage
  useEffect(() => {
    const workflowId = uuidV4();
    initTracerSetup(workflowId);
    sessionStorage.setItem(StorageKeys.WORKFLOW_ID, workflowId);
    console.debug('[Setup] workflowId:', workflowId);
  }, []);

  const { mutate, isPending } = useStartTenantSetup({ setLoading, setDpaNoActionNeeded });

  // Trigger the mutation when the component mounts
  useEffect(() => {
    mutate();
  }, [mutate]);

  // Handle form submission and navigation
  const onSubmit = () => {
    if (dpaNoActionNeeded) {
      const features = getFeatureFlags();
      if (features.ICC_TOKEN_PAGE_DISABLED) {
        navigate({ pathname: '/setup/org-info', search: `?${searchParams.toString()}` });
      } else {
        navigate({ pathname: '/setup/integration-token', search: `?${searchParams.toString()}` });
      }
    } else {
      navigate({ pathname: '/setup/data-processing-addendum', search: `?${searchParams.toString()}` });
    }
  };

  return isPending ? (
    <></>
  ) : (
    <>
      <div>
        <p className="subtitle">{t(`${TRANSLATION_ROOT}.description`)}</p>
      </div>
      <div>
        <span className="primary-body">
          <ul>
            <li>{t(`${TRANSLATION_ROOT}.list-content-1`)}</li>
            <li>{t(`${TRANSLATION_ROOT}.list-content-2`)}</li>
            <li>{t(`${TRANSLATION_ROOT}.list-content-3`)}</li>
          </ul>
        </span>
      </div>
      <div style={{ marginTop: '2rem' }}>
        <Form>
          <ContinueButton
            autoFocus
            data-testid="proceed-btn"
            label={t(`${TRANSLATION_ROOT}.next-button`)}
            onClick={onSubmit}
            type="primary"
          />
        </Form>
      </div>
    </>
  );
};

export default SetupLandingPage;

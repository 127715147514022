import { PurchasedProductCard } from '../../../components/landing/PurchasedProductCard/PurchasedProductCard';
import { UnpurchasedProductCard } from '../../../components/landing/UnpurchasedProductCard/UnpurchasedProductCard';
import PageFooter from '../../../components/layout/PageFooter';
import PageHeader from '../../../components/layout/PageHeader';
import './PortalLanding.less';
import { Button } from '@imprivata-cloud/components';
import { useEffect, useState } from 'react';
import { getLandingPage } from '../../../api/portalServices';
import type { GetLandingPageResponse, PurchasedProduct } from '../../../api/types';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { AppError } from '../../../errorHandler/errors';
import { SPANS_PORTAL, tracer } from '../../../utils/tracer';
import { CUSTOMER_EXPERIENCE_CENTER_URL } from '../../constants';
import { useIdleTimeout } from '../../hooks/useIdleTimeout';

export type PortalLandingProps = {
  tenantId: string;
  workflowId?: string;
  onLogout: (tenantId: string) => void;
};

export const PortalLanding = ({ workflowId, tenantId, onLogout }: PortalLandingProps) => {
  const { emitError } = useNotifications();
  const [data, setData] = useState<GetLandingPageResponse>();

  const userLogout = async () => {
    console.debug('Logout');
    onLogout(tenantId);
  };

  const { startIdleTimer } = useIdleTimeout(30, userLogout);
  startIdleTimer();

  useEffect(() => {
    const getPortalInfo = async () => {
      return await getLandingPage(workflowId || '', tenantId);
    };
    tracer.startSpan(SPANS_PORTAL.get_portal_info);
    getPortalInfo()
      .then((response) => {
        tracer.endSpan(SPANS_PORTAL.get_portal_info);
        setData(response);
      })
      .catch((e) => {
        tracer.endSpan(SPANS_PORTAL.get_portal_info, { error: e });
        console.error('Error fetching portal info:', e);
        emitError(new AppError(e));
      });
  }, [workflowId, tenantId, emitError]);

  return (
    <div className="landing-page" data-testid="landing-page">
      <PageHeader
        title={'Imprivata Access Management'}
        className={'page-header'}
        rightSlot={
          <Button type={'text'} onClick={userLogout}>
            Logout
          </Button>
        }
      />
      <div className="page-header-container">
        <div className={'page-header'}>
          <a href={CUSTOMER_EXPERIENCE_CENTER_URL} target={'_blank'} rel={'noreferrer'}>
            CUSTOMER EXPERIENCE CENTER
          </a>
        </div>
      </div>
      <div className="content-container">
        <div className="section">
          <div className={'iam-product-summary-container'}>
            <div>
              <h1>Imprivata Access Management</h1>
            </div>
            <div>
              <img src={data?.orgInfo?.logoUrl} alt="" style={{ width: '100px', height: '100px' }} />
            </div>
          </div>
        </div>
        {data?.productInfo?.purchasedProducts && data?.productInfo?.purchasedProducts.length > 0 && (
          <div className="section fluid">
            {data?.productInfo?.purchasedProducts?.map((product: PurchasedProduct) => (
              <PurchasedProductCard
                key={product.productTag}
                productTag={product.productTag}
                productName={product.displayName}
                productLink={product.startSsoUrl}
                spId={product.spId}
              />
            ))}
          </div>
        )}
        <div className="section fluid">
          {data?.productInfo?.nonPurchasedProducts?.map((product) => (
            <UnpurchasedProductCard
              key={product.productTag}
              productName={product.displayName}
              productLink={product.learnMoreUrl}
            />
          ))}
        </div>
      </div>
      <PageFooter />
    </div>
  );
};

// Copyright 2024, Imprivata, Inc.  All rights reserved.

import Icon from '@ant-design/icons';
import { Button, InputBox } from '@imprivata-cloud/components';
import { Form } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { Metadata } from '../../../api/types';
import copyIcon from '../../../assets/icons/copy.svg?react';
import ContinueButton from '../../../components/continueButton/ContinueButton';
import './ExternalIdpInfo.less';
import * as api from '../../../api/setupServices';
import { ErrorCode } from '../../../errorHandler/constants';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { AppError, FatalError } from '../../../errorHandler/errors';
import { getSPMetadataURL, copyToClipboard } from '../../../utils/utils';
import { STEPS } from '../../constants';
import { tracer, SPANS_SETUP } from '../../../utils/tracer';

const TRANSLATION_ROOT = `setup.${STEPS.EXTERNAL_IDP_INFO}.content`;

const FILE_EXTENSION = '.xml';
const FILE_INPUT_TYPE = 'file';

interface FormValues {
  IdPName: string;
  IdPURL: string;
  IdPXML: string;
}

const isValidURL = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};

const handleFileInputChange = (
  event: Event,
  resolve: (name: string, idpXml: string) => void,
  reject: (error: unknown) => void,
) => {
  const target = event.target as HTMLInputElement;
  const file = target.files?.[0];

  if (!file) {
    reject(new Error('No file selected.'));
    return;
  }

  const fileExtension = file.name.split('.').pop()?.toLowerCase();
  if (fileExtension === 'xml') {
    const reader = new FileReader();

    reader.onerror = (e) => {
      console.log('Failed to read the file. Please try again.');
      reject(e);
    };

    reader.onload = () => {
      try {
        const text = reader.result as string;
        const encoder = new TextEncoder();
        const data = encoder.encode(text);
        const idpXml = btoa(String.fromCharCode(...new Uint8Array(data)));

        resolve(file.name, idpXml);
      } catch (error) {
        console.log('There was an issue processing the file. Please try again.');
        reject(error);
      }
    };

    reader.readAsText(file, 'UTF-8');
  } else {
    console.log('Please select an XML file.');
    reject(new Error('Please select an XML file.'));
  }
};

const ExternalIDPInfo = () => {
  const [form] = Form.useForm<FormValues>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { emitError } = useNotifications();

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [fileName, setFileName] = useState<string>('');
  const [isIdpUrl, setIsIdpUrl] = useState<boolean>(false);
  const [readOnlyXML, setReadOnlyXML] = useState<boolean>(true);
  const spMetadataURL = getSPMetadataURL();

  const handleInputChange = () => {
    const values = form.getFieldsValue() as FormValues;
    const isEnabled = Boolean(values.IdPName && (values.IdPURL || values.IdPXML));
    setIsIdpUrl(!!values.IdPURL);
    setIsSubmitEnabled(isEnabled);
  };

  const handleInputBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue === '') {
      form.setFieldsValue({ IdPXML: '' });
      setFileName('');
      setReadOnlyXML(true);
    } else {
      setFileName(inputValue);
      setReadOnlyXML(false);
    }
  };

  const handleInputBoxFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const handleInputBoxKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Backspace' && event.key !== 'Delete') {
      event.preventDefault();
    } else {
      event.preventDefault();
      form.setFieldsValue({ IdPXML: '' });
      setFileName('');
      setReadOnlyXML(true);
      setIsSubmitEnabled(false);
    }
  };

  // Main handler for updating xml data into form
  const handleFileButtonClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = FILE_INPUT_TYPE;
    fileInput.accept = FILE_EXTENSION;

    // Handle the file input change
    fileInput.onchange = (event) => {
      // Update the file name for display
      const resolve = (name: string, idpXml: string) => {
        form.setFieldsValue({ IdPXML: idpXml });
        setFileName(name);
        console.log('[handleFileButtonClick] File selected:', name);
        handleInputChange();
      };

      // TODO: Handle errors
      const reject = (e: unknown) => {
        console.log('Error:', e);
      };
      setReadOnlyXML(false);
      handleFileInputChange(event, resolve, reject);
    };
    fileInput.click();
  };

  // Main form submit handler.
  //
  // NOTE: This will be used to display banners on validation errors
  // TODO: we must replace "Throw new Error" and "console.log" with proper UI error handling
  const finishForm = async () => {
    try {
      const values = form.getFieldsValue() as FormValues;
      if (!values.IdPName) {
        throw new Error('The Identity Provider Name (IdPName) is required.');
      }

      if (!values.IdPURL && !values.IdPXML) {
        throw new Error('Either the Identity Provider URL (IdPURL) or XML file (IdPXML) is required.');
      }

      if (values.IdPURL && !isValidURL(values.IdPURL)) {
        throw new Error('The provided URL is not valid.');
      }

      // Send data to backend
      // TODO: What is the response? Error handling?

      let metadata: Metadata = {
        rawMetadata: values.IdPXML,
      };

      if (values.IdPURL) {
        metadata = {
          metadataUrl: values.IdPURL,
        };
      }

      tracer.startSpan(SPANS_SETUP.icp_metadata_save);
      await api
        .icpMetadataSave({
          displayName: values.IdPName,
          metadata,
        })
        .then(async () => {
          tracer.endSpan(SPANS_SETUP.icp_metadata_save);
          tracer.startSpan(SPANS_SETUP.complete_setup);
          await api
            .sendTenantSetupCompleteRequest(false)
            .then(() => {
              tracer.endSpan(SPANS_SETUP.complete_setup);
              navigate({ pathname: '/setup/setup-complete' });
            })
            .catch((e) => {
              tracer.endSpan(SPANS_SETUP.complete_setup, {error: e});
              // If the complete setup call fails, show a full page error.
              emitError(new FatalError(e));
            });
        })
        .catch((error) => {
          tracer.endSpan(SPANS_SETUP.icp_metadata_save, {error});
          console.log('Error:', error);
          emitError(new AppError(error));
        });
    } catch (error) {
      if (error instanceof Error) {
        console.log(`Form validation failed: ${error.message}`);
      } else {
        console.log('Unexpected error:', error);
      }
      emitError(new AppError({ code: ErrorCode.UNKNOWN }));
    }
  };

  // Copy the link to the clipboard
  const handleCopyClick = () => {
    copyToClipboard(spMetadataURL.trim());
  };

  return (
    <div className="external-idp-container">
      <Form
        name="IdpInfo"
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={finishForm}
        onValuesChange={handleInputChange}
      >
        <div className={'subtitle'} />

        {/* Name */}
        <Form.Item label={t(`${TRANSLATION_ROOT}.idp-name-input`)} name={'IdPName'} rules={[{ required: true }]}>
          <InputBox size="large" data-testid="idp-name-input" className={'input'} />
        </Form.Item>

        {/* URL */}
        <Form.Item label={t(`${TRANSLATION_ROOT}.idp-url-input`)} name={'IdPURL'}>
          <InputBox size="large" data-testid="idp-url-input" className={'input'} disabled={!!fileName} />
        </Form.Item>

        {/* IdP XML file */}
        <Form.Item label={t(`${TRANSLATION_ROOT}.idp-xml-input`)} name={'IdPXML'}>
          <div className="xml-form-item">
            <InputBox
              size="large"
              data-testid="idp-xml-input"
              className={'input'}
              value={fileName}
              onChange={handleInputBoxChange}
              onFocus={handleInputBoxFocus}
              onKeyDown={handleInputBoxKeyDown}
              readOnly={readOnlyXML}
            />
            <Button
              type="text"
              className={'browse-button'}
              label={t(`${TRANSLATION_ROOT}.idp-xml-browse-button`)}
              data-testid="idp-xml-browse-button"
              size="middle"
              onClick={handleFileButtonClick}
              disabled={isIdpUrl}
            />
          </div>
        </Form.Item>

        {/* Copy link */}
        <Form.Item label={t(`${TRANSLATION_ROOT}.sp-metadata-label`)}>
          <div className="link-content">
            <InputBox data-testid="idp-xml-input" className={'input'} value={spMetadataURL} readOnly />
            <Button
              className={'copy-button'}
              size="large"
              data-testid="copy-button"
              icon={<Icon component={copyIcon} />}
              onClick={handleCopyClick}
              label={t('common.copy')}
            />
          </div>
        </Form.Item>

        {/* Submit button */}
        <Form.Item shouldUpdate>
          <ContinueButton
            disabled={!isSubmitEnabled}
            htmlType="submit"
            label={t(`${TRANSLATION_ROOT}.next-button`)}
            validateDirty={['IdPName', 'IdPURL', 'IdPXML']}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ExternalIDPInfo;

import { InputBox, Button } from '@imprivata-cloud/components';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { createToken } from '../../../api/setupServices';
import ContinueButton from '../../../components/continueButton/ContinueButton';
import { tracer, SPANS_SETUP } from '../../../utils/tracer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import Icon from '@ant-design/icons';
import CopyIcon from '../../../assets/icons/copy.svg?react';
import { copyToClipboard } from '../../../utils/utils';
import { AppError, type ApiError } from '../../../errorHandler/errors';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { useMutation } from '@tanstack/react-query';

const TRANSLATION_ROOT = 'setup.integration-token.content';

const fieldIntegrationId = 'IntegrationId';

type FormValues = {
  [fieldIntegrationId]: string;
};

const IntegrationTokenPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [installationTokenValue, setInstallationTokenValue] = useState('')
  const { emitError } = useNotifications();

  const [form] = Form.useForm<FormValues>();

  const onContinue = () => {
    navigate({ pathname: '/setup/org-info', search: `?${searchParams.toString()}` });
  };

  const { mutate: onSubmit, isPending } = useMutation({
    mutationFn: (values: FormValues) => {
      tracer.startSpan(SPANS_SETUP.connect_client_create);
      return createToken({
        enterpriseId: values[fieldIntegrationId],
        installationTokenExpirationInMinutes: 60
      });
    },
    onSuccess: (clientCreateResponse) => {
      setInstallationTokenValue(clientCreateResponse?.clientMetadata?.installationTokenValue);
      tracer.endSpan(SPANS_SETUP.connect_client_create);
    },
    onError: (e: ApiError) => {
      tracer.endSpan(SPANS_SETUP.connect_client_create, { error: e });
      console.error(SPANS_SETUP.connect_client_create, e);
      emitError(new AppError(e));
    },
  })

  return (
    <>
      <p>{t(`${TRANSLATION_ROOT}.text-1`)}</p>
      <Form form={form} onFinish={onSubmit}>
        <Form.Item name={fieldIntegrationId} rules={[{ required: true }]}>
          <InputBox label={t(`${TRANSLATION_ROOT}.input`)} required autoFocus/>
        </Form.Item>
        <ContinueButton
          validateDirty
          htmlType="submit"
          label={t(`${TRANSLATION_ROOT}.button`)}
          disabled={isPending}
          data-testid="create-token-button"
        />
        <Button
          size="large"
          style={{
            visibility: installationTokenValue ? 'visible' : 'hidden',
            border: 'none',
            boxShadow: 'none',
          }}
          icon={<Icon component={CopyIcon}/>}
          label={t(`${TRANSLATION_ROOT}.copy`)}
          onClick={() => copyToClipboard(installationTokenValue)}
          block
          data-testid="copy-token-button"
        />
        <p>
          {t(`${TRANSLATION_ROOT}.text-2`)}
          <a href="https://www.imprivata.com" target="_blank" rel="noopener noreferrer">
            {t('common.see-instructions')}.
          </a>
        </p>
      </Form>
      <ContinueButton
        htmlType="submit"
        label={t('common.continue-button')}
        onClick={onContinue}
        data-testid="continue-button"
      />
    </>
  );
};

export default IntegrationTokenPage;

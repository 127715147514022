// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { _getEnv } from './api/utils';
// NOTE: This is temporary until feature flags have been properly implemented
import featureFlags from './featureFlags.json';

export type FeatureFlags = typeof featureFlags;

export type FeatureConfig = {
  ICC_TOKEN_PAGE_DISABLED: boolean;
};

export function getFeatureFlags(): FeatureConfig {
  const { currentEnv } = _getEnv();
  let features: FeatureConfig = featureFlags['prod' as keyof FeatureFlags];

  if (Object.keys(featureFlags).includes(currentEnv)) {
    features = featureFlags[currentEnv as keyof FeatureFlags];
  }

  console.debug('[featureFlagUtils] feature flags: ', {
    currentEnv,
    features,
  });
  return features;
}
